import { axios } from '@/lib/axios';
import { TConversation, TMessage, TMessageHistory, TModarareResposne } from '../../types';

import {
  IAPIGetCancellationRequests,
  IAPIGetConversations,
  IAPIGetConversationsByMemberId,
  IAPIGetHistoryConversation,
  IAPIGetMessagesOfConversation,
  IAPIGetNotModeratedMessages,
  IAPIGetWaitingMessages,
  IAPIPatchConversation,
  IAPIPostMessageInConversation,
} from '../../types/interfaces/api/conversations';

export const getListExchanges = ({
  status = 'ALL',
  closeWithoutConfirmation,
  searchTerm,
  limit,
  page,
}: {
  searchTerm?: string;
  status?: 'ACCEPTED' | 'CLOSED' | 'ALL';
  closeWithoutConfirmation?: boolean;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetConversations> => {
  const params: { [key: string]: any } = {
    searchTerm,
    closeWithoutConfirmation,
    limit,
    page,
  };

  if (status !== 'ALL') {
    params.status = status;
  }

  return axios.get('/chat-s/bo/api/v1/conversations', {
    params,
  });
};

export const closeExchange = ({
  conversationId,
  closeReason,
}: {
  conversationId: string;
  closeReason?: string;
}): Promise<IAPIPatchConversation> => {
  return axios.patch(`/chat-s/bo/api/v1/conversations/${conversationId}/close`, {
    closeReason,
  });
};

export const getWaitingMessages = ({
  search,
  limit,
  page,
  status,
}: {
  search?: string;
  limit?: number;
  page?: number;
  status?: 'VALIDATED' | 'PENDING' | 'REJECTED';
} = {}): Promise<IAPIGetWaitingMessages> => {
  return axios.get(`/chat-s/bo/api/v1/messages`, {
    params: {
      limit,
      page,
      search,
      status: 'PENDING',
    },
  });
};

export const getConversationsByMemberId = (
  idMember: string,
  {
    limit,
    page,
  }: {
    limit?: number;
    page?: number;
  } = {}
): Promise<IAPIGetConversationsByMemberId> => {
  return axios.get(`/chat-s/bo/api/v1/conversations/${idMember}/messages/`, {
    params: {
      limit,
      page,
      status: 'VALIDATED'
    },
  });
};

export const getMessagesOfConversation = (
  idConversation: string,
  {
    limit,
    page,
  }: {
    limit?: number;
    page?: number;
  } = {}
): Promise<IAPIGetHistoryConversation> => {
  return axios.get(`/chat-s/bo/api/v1/conversations/${idConversation}/messages`, {
    params: {
      limit,
      page,
      status: 'VALIDATED'
    },
  });
};

export const getNotModeratedMessages = ({
  text,
  limit,
  page,
  all
}: {
  text?: string;
  limit?: number;
  page?: number;
  all?: boolean;
} = {}): Promise<IAPIGetNotModeratedMessages> => {
  return axios.get(`/chat-s/bo/api/v1/messages`, {
    params: {
      limit,
      page,
      // text,
      status: 'PENDING',
      all
    },
  });
};

export const getModeratorNotModeratedMessages = ({
  limit,
  page,
}: {
  text?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetNotModeratedMessages> => {
  return axios.get(`/chat-s/bo/api/v1/messages/broken`); 
}

export const getCancellationRequest = ({
  limit,
  page,
}: {
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetCancellationRequests> => {
  return axios.get(`/chat-s/bo/api/v1/conversations`, {
    params: {
      limit,
      page,
      status:"CLOSED",
      closeWithoutConfirmation: true
    },
  });
};

export const getConversationById = (
  conversationId: string
): Promise<TConversation> => {
  return axios.get(`/chat-s/bo/api/v1/conversations/${conversationId}/messages`);
};

export const getConversationHistoryById = (
  conversationId: string,
  page?: number,
): Promise<IAPIGetHistoryConversation> => {
  return axios.get(`/chat-s/bo/api/v1/conversations/${conversationId}/messages`,
    {
      params: {
        status: 'VALIDATED',
        page
      }
    }
  );
};

export const closeMessage = (
  conversationId: string
 ): Promise<TConversation> => {
  return axios.patch(`/chat-s/bo/api/v1/conversations/${conversationId}/close`);
}

export const addNoteToConversation = (
  conversationId: string,
  message: string
): Promise<IAPIPostMessageInConversation> => {
  return axios.post(`/chat-s/bo/api/v1/conversations/${conversationId}/messages`, {
    message,
  });
};

// export const moderateMessage = ({
//   conversation_id,
//   message_id,
//   note = "",
//   message = "",
//   validate,
//   operation = "message-validation",
// }: {
//   conversation_id: string;
//   message_id: string;
//   note?: string;
//   message?: string;
//   validate: boolean;
//   operation?: "message-validation" | "message-to-sender";
// }): Promise<TMessage> => {
//   return axios.patch(
//     `/conversations/${conversation_id}/messages/${message_id}`,
//     {
//       operation,
//       conversation_id,
//       message_id,
//       validate,
//       note,
//       message,
//     }
//   );
// };

export const moderateMessage = ({
  status,
  reason,
  messageId,
}: {
  status: 'VALIDATED' | 'REJECTED';
  reason?: string;
  messageId: string;
}): Promise<TModarareResposne> => {
  return axios.patch(`/chat-s/bo/api/v1/messages/${messageId}/moderate/`, {
    status,
    reason,
  });
};

export const commentMessage = ({
  message,
  messageId,
}: {
  message: string;
  messageId: string;
}): Promise<TMessage> => {
  return axios.patch(`/chat-s/bo/api/v1/messages/${messageId}/comment/`, {
    message,
  });
};
